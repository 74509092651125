<template>
    <div class="Loginyhyygj border-box">
        <img class="margin-auto" :src="'./img/Login/'+LogoUrl+'.png'" alt="">
        <div :class="{'ShowAnimate':!ShowAnimate}" class="margin-auto flex column border-box">
            <div v-for="(item,index) in Placeholder" :key="index" class="input-box relative allw font14 mab15">
                <img :src="'./img/Login/' + ImgArray[index] +'.png'" class="absolute">
                <input v-model="LoginParmas[TypeArray[index]]" :placeholder="item" :type="TypeArray[index]"
                       class="color333 border-box allw">
            </div>
            <div class="allw btn-box text-center font20">
                <div @click="GameLoginFn" class="pointer allw opacity8">登录</div>
                <div @click="FreeLogin" class="pointer colorfff mat15 opacity8 hover">免费试玩</div>
            </div>
            <!--    <div v-if="isregister" @click="wakeregister" class="font32 mat15 register-btn text-center margin-auto pointer">注册</div>-->
        </div>
        <div v-if="DefaultConfig.LoginTemplate === 'yhyygj'" id="APPDOWN" class="font18 colorfff text-center">
            <div id="qrcode" class="allh allw mab10"></div>
            手机版扫描安装
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {GameLogin, QuickLogin, PlayerDemo, ApiGetConfig, Instance} from '../../Api';
    import QRCode from 'qrcodejs2';

    export default {
        name: 'LoginInyhyygj',
        computed: {...mapState(['SocketFn', 'DefaultConfig', 'ReInit'])},
        props: {
            'ShowAnimate': {
                default() {
                    return true
                }, type: Boolean
            }
        },
        data() {
            return {
                CallBack: ['CallSocketLoginFn'],
                Placeholder: ['请输入账号', '请输入密码'],
                ImgArray: ['user_yhyygj', 'mima_yhyygj'],
                TypeArray: ['text', 'password'],
                LoginParmas: {'text': '', 'password': ''},
                Isfree: false, //是否是玩
                IsRegister: false,
                LogoUrl: '', //logo图片
            }
        },
        created() {
            this.$nextTick(() => {
                this.Init();
            });
        },
        watch: {
            'DefaultConfig': {
                handler(n, o) {
                    if (n.LoginTemplate === 'yhyygj') { //永源国际需要展示二维码
                        ApiGetConfig({}).then((resp) => {
                            let qrcode = new QRCode('qrcode', {
                                // width: 232,  // 设置宽度
                                // height: 232, // 设置高度
                                text: resp.data.appDownloadUrl
                            })
                        });
                    }
                    this.LoginParmas.text = n.DefaultName || "";
                    this.LoginParmas.password = n.DefaultsPassword || "";
                    this.LogoUrl = `logo_${n.LoginTemplate}`;
                }, deep: true, immediate: true
            },
            'ReInit': {
                handler(n, o) {
                    if (n) {
                        this.ReBaseUrl();
                        this.SetStore({
                            Prop: 'ReInit',
                            Val: false
                        });
                    }
                }
            }
        },
        methods: {
            Init() {
                this.IsRegister = this.$ReConfig.InviteCode ? true : false; //是否显示注册按钮
                this.ReBaseUrl();
                this.Auto();
            },
            Auto() { //是否自动登录
                let query = this.$route.query;
                sessionStorage.Defaultroom = this.ToString({
                    GameType: query.gameType || 0,
                    HallType: query.hallType || 0
                });
                if (query.isdemo && query.isdemo !== '0') { //免费试玩
                    this.FreeLogin();
                } else if (query.launchtoken) {  //快速登陆
                    this.QuickLoginFn(query.launchtoken);
                }
            },
            QuickLoginFn(launchtoken) {  //自动登录
                QuickLogin({launchtoken}).then((Resp) => {
                    sessionStorage.LaunchToken = true;  //是自动登录过来的
                    this.CallLoginFn(Resp);
                });
            },
            ForRegister(DefaultName, DefaultsPassword) {  //为注册完成编写的自动登录
                this.LoginParmas.text = DefaultName;
                this.LoginParmas.password = DefaultsPassword;
                this.GameLoginFn();
            },
            ReBaseUrl() {
                Instance.defaults.baseURL = this.$ReConfig.Api || 'http://182.61.43.84:9092'; //需要重新配置axios的请求地址
            },
            GameLoginFn() {
                this.ReBaseUrl();
                let {text, password: Password} = this.LoginParmas;
                if (text === '' || Password === '') {
                    this.Toast('2', '账号或密码错误');
                    return;
                }
                GameLogin({
                    'username': text,
                    'password': Password
                }).then((Resp) => {
                    this.Isfree = false;
                    this.CallLoginFn(Resp);
                });
            },
            CallLoginFn(Resp) {  //登录、试玩接口的回调
                if (Resp.code !== 0) return;
                let Fn = () => {
                    if (!this.Isfree) {
                        sessionStorage.Authorization = Resp.data.token;  //缓存tokend
                        this.SocketLogin(Resp.data.sign, Resp.data.timestamp, Resp.data.username);
                    } else {  //免费试玩不需要请求个人中心
                        sessionStorage.removeItem('Authorization');
                        this.SocketLogin(Resp.data.sign, Resp.data.timestamp, Resp.data.username);
                    }
                };
                this.SocketApi.InitSock(Resp.data.serverAddr).then(() => {  //链接ws
                    Instance.defaults.baseURL = Instance.defaults.baseURL + '/gameApi';  //第一次修改请求地址
                    Fn();
                });
            },
            SocketLogin(Sign, Timestamp, Freename) {
                let {text} = this.LoginParmas;
                this.SocketApi.SendSock([this.stype.Center, this.cmd.SignLogin, 0, {
                    "sign": Sign,
                    "timestamp": Timestamp,
                    "username": Freename || text,
                }], 'CallSocketLoginFn');
            },
            CallSocketLoginFn(Resp) {
                this.SetStore({
                    Prop: 'UserInfo',
                    Val: Resp
                });
                this.Toast("1", "登录成功", {
                    Time: 3, CallBack: () => {
                        this.$router.replace("GameHall"); //直接重定向到游戏中心 避免手动返回到登陆页面
                    }
                });
            },
            FreeLogin() {  //试玩
                PlayerDemo({
                    player: "888",
                    timestamp: new Date().getTime()
                }).then((Resp) => {
                    this.Isfree = true;
                    this.CallLoginFn(Resp);
                });
            },
        }
    }
</script>

<style lang="less">
    html {
        background: url("../../../public/img/Login/bg_yhyygj.png") no-repeat top center/cover;
    }

    .ShowAnimate {
        transition: .5s;
        opacity: 1 !important;
        transform: rotateY(0deg) !important;
    }

    .Loginyhyygj {
        padding-top: .45rem;

        > img {
            margin-top: 0.77rem;
        }

        > div {
            width: 3.4rem;
            margin-top: .45rem;
            /*padding: 30px 0;*/
            /*transform: rotateY(90deg);*/
            /*opacity: 0;*/

            .input-box {
                /*margin-bottom: 35px;*/

                input {
                    height: .5rem;
                    line-height: .5rem;
                    border-radius: 6px;
                    padding: 0 .15rem 0 .65rem;
                    background-color: #000000;
                    color: #e9d56c;
                }

                input::-webkit-input-placeholder {
                    color: #e9d56c;
                }

                input::-moz-placeholder { /* Mozilla Firefox 19+ */
                    color: #e9d56c;
                }

                input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                    color: #e9d56c;
                }

                input:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #e9d56c;
                }

                > img {
                    height: .23rem;
                    top: 50%;
                    left: .15rem;
                    transform: translate(0, -50%);
                    border-right: 1px solid #e9d56c;
                    padding-right: .15rem;
                }
            }

            .btn-box {
                div {
                    color: #69300f;

                    &:first-child {
                        height: .45rem;
                        line-height: .45rem;
                        background: url("../../../public/img/Login/login_btn.png") no-repeat left top/cover;
                        margin-right: 60px;
                    }

                    &:last-child {
                        text-align: end;
                    }
                }
            }

            .register-btn {
                width: 5rem;
                height: .7rem;
                line-height: .7rem;
                background: url("../../../public/img/Login/dl_zc_btn.png") no-repeat center center/ 100% 100%;

                &:hover {
                    background: url("../../../public/img/Login/dl_zc_btn_hover.png") no-repeat center center/ 100% 100%;
                    color: #ffffff;
                }
            }
        }

        #APPDOWN {
            position: fixed;
            width: 2.1rem;
            height: 2.1rem;
            right: 3rem;
            top: 3.5rem;
            margin: 0;

            img {
                width: 100%;
                height: 100%;
                padding: .1rem;
                box-sizing: border-box;
                background-color: #ffffff;
            }
        }
    }
</style>
